import { createMachine, assign } from "xstate";
import { getUrlParam, addValueCookie, getValueCookie } from "../Utilities/httpUtils";
import axios from "axios";
import { channel, docTypes, codesNoSDK, device, contentType, sourceId } from "../Constants/Constants"
import config from "src/config";
import { v4 as uuidv4 } from 'uuid';
import { decryptDataAesGcm, generateAESkey, generateJWE, getBodyValidateUser, getBodyActivateUser, getBodyValidateTransaction } from '../Utilities/functions'
import { saveLogs } from "../Services/SaveLogs";

const URLACTIVATEUSER = config.url.URLACTIVATEUSER;
const URLVALIDATEUSER = config.url.URLVALIDATEUSER;
const URLVALIDATEUSERVISIBLE = config.url.URLVALIDATEUSERVISIBLE;
const URLVALIDATETRANSACTION = config.url.URLVALIDATETRANSACTION;
const URLRETRYBIOMETRICS = config.url.URLRETRYBIOMETRICS;
const URLBFPUBLICKEY = config.url.URLBFPUBLICKEY;

const conditions = {
  successServiceConsume: (_context, event) => event?.data?.response?.data?.resultCode ?? false,
  successServiceJws: (_context, event) => event?.data?.response?.data?.jws ?? false,
  successEncryptKey: (_context, event) => event.data.jwe && event.data.aesKey ? true : false,
  isCookie: (_context, event) => event.data.cookie && event.data.sessionId ? true : false,
  isError: (_context, event) => event.data.error ? true : false,
  successRefreshKey: (_context, event) => event.data.jwe ? true : false,
  isUnauthorized: (_context, event) => event.data.response.data.resultCode === codesNoSDK.unauthorized.code,
  failedRecatpcha: (_context, event) => event.data.response.data.resultCode === codesNoSDK.failedRecatpcha.code,
  failedRecatpchaChallenge: (_context, event) => event.data.response.data.resultCode === codesNoSDK.failedRecatpchaChallenge.code,
  failedRecatpchaRetry: (_context, event) => event.data.response.data.resultCode === codesNoSDK.failedRecatpchaRetry.code && !_context.challenge
};

const actions = {
  setJweKey: assign({ jweKey: (_context, event) => event.data.jwe }),
  setAesKey: assign({ aesKey: (_context, event) => event.data.aesKey }),
  setDataStr: assign({ dataStr: (_context, event) => event.data.dataStr }),
  setDefaultServiceError: assign({ code: _context => codesNoSDK.errorServiceConsume.code }),
  setServiceError: assign({ code: (_context, event) => event.data.response.data.resultCode}),
  setJwsValue: assign({ jws: (_context, event) => conditions.successServiceJws(_context, event) ?  event.data.response.data.jws : ""}),
  setSso: assign({ cookie: (_context, event) => event.data.cookie }),
  setSetSession: assign({ sessionId: (_context, event) => event.data.sessionId }),
  setSuccessGeneretingKey: assign({ codeLog: (_context) => 'successGeneretingKey' }),
  setDataResponse: assign({ dataResponse: (_context, event) => event.data.data }),
  setCodeResult: assign({ code: (_context, event) => event.data.resultCode }),
  setCodeError: assign({ code: (_context, event) => event.code })
};


const fetchValidateParameters = async context => {
  const data = await validateParams();
  if(!data.error){
    return { clientId: data.clientId, data: data.data, sessionId: context.sessionId ?  context.sessionId : data.sessionId, customerTransaction: data.customerTransaction };
  }else{
    return {error: true}
  }
};

const validateParams = async () => {
  const clientId = getUrlParam("clientId")
  const data = getUrlParam("data")
  const customerTransaction = getUrlParam("customerTransaction")
  const sessionId = uuidv4();
  const requiredFields = [clientId, data, sessionId];

  if (!requiredFields.every(field => field)) {
    return { error: true };
  }
  return { customerTransaction: customerTransaction, clientId: clientId, data: data, sessionId: sessionId};
};

const validateClientState = async context => {
  const PARAMS = await getBodyValidateUser(context);
  const headersAuthorize = {'Content-Type': contentType, 'recaptcha': context.recaptcha, 'customer': context.clientId, 'session-id': context.sessionId, 'sso': context.cookie};
  const url = context.challenge ? URLVALIDATEUSERVISIBLE : URLVALIDATEUSER;
  return axios.post(url, PARAMS.data, { headers: headersAuthorize }).then(response => response.data);
};

const decryptClientData = async context => decryptDataAesGcm(context)

const activateUser = async context => {
  const PARAMS = await getBodyActivateUser(context);
  const isTestMode = config.TEST_MODE === "true";
  const customer = isTestMode ? config.CUSTOMER_RESCUE : context.clientId;
  const headersAuthorize = {'Content-Type': contentType, 'customer': customer, 'session-id': context.sessionId, 'sso': context.cookie};
 
  let processId;
  if (isTestMode) {
    processId = context.documentNumber === config.TEST_DOCUMENT ? config.TEST_PROCESS_ID : context.documentNumber;
  } else {
    processId = context.processId;
  }

  return axios.post(`${URLACTIVATEUSER}${channel}/${processId}/1`, PARAMS.data, { headers: headersAuthorize }).then(response => response.data);
};

const validateTransaction = async context => {
  const PARAMS = await getBodyValidateTransaction(context);
  const headersAuthorize = {'Content-Type': contentType, 'Token': context.token, 'customer': context.clientId, 'session-id': context.sessionId, 'sso': context.cookie};
  return axios.post(URLVALIDATETRANSACTION, PARAMS.data,{ headers: headersAuthorize }).then(response => response.data);
};

const checkTermsAndConditions = async context => {
  if(context.showTermsAndConditions){
    return {showTyC: false}
  }else{
    return {showTyC: true}
  }
}

const checkUserState = async context => {
    return {blocked: context.blocked}
}

const checkCustomerTransaction = async context => {
  if(context.customerTransaction === context.customerTransactionUrl || context.customerTransactionUrl === null){
    return {mismatch: false}
  }else{
    return {mismatch: true}
  }
}

const saveUserTries = async context => {
  const PARAMS = await getBodyValidateTransaction(context);
  const headersAuthorize = {'Content-Type': contentType, 'Token': context.token, 'customer': context.clientId, 'session-id': context.sessionId};
  return axios.post(URLRETRYBIOMETRICS, PARAMS.data, { headers: headersAuthorize }).then(response => response.data);
};

const getBfKey = async () => axios.get(URLBFPUBLICKEY).then(response => response.data);

const generateBfKey = async context => {
  const aesKey = generateAESkey(context);
  const data = {key: aesKey, device: JSON.stringify(device).replace(/\s/g, "")}
  const dataStr = JSON.stringify(data)
  if(aesKey){
    const jwe =  await generateJWE(dataStr, context.bffKey)
    return {jwe: jwe, aesKey: aesKey, dataStr: dataStr}
  }else{
    return {error: true}
  }
}

const refreshBfKey = async context => {
  const jwe =  await generateJWE(context.dataStr, context.bffKey)
  return {jwe: jwe}
}

const validateCookie = () => async () => {
  const cookie = await getValueCookie("SSOFUB")
  const sessionId = await getValueCookie("SSNFUB")
  return {cookie: cookie, sessionId: sessionId}
};


const initialContext = {
    clientId: "",
    data: "",
    documentType: "TIPDOC_FS001",
    documentNumber: "1111111111",
    email: "",
    phone: "",
    phoneIndicative: "",
    transactionId: "",
    token: "",
    redirectUrl: "",
    recaptcha: "",
    processId: "",
    code: "",
    codeLog: "",
    enrollment: false,
    documentTypeEq: "",
    sessionToken: "",
    sessionId: "",
    customerTransaction: "",
    customerTransactionUrl: "",
    showTermsAndConditions: "",
    blocked: false,
    attemps: 0,
    encryptedData: "",
    encryptedDataClient: "",
    iv: "",
    ivClient: "",
    contextScreen: true,
    processFailed: false,
    bffKey: "",
    jweKey: "",
    aesKey: "",
    jws: "",
    cookie: "",
    dataStr:"",
    resultLog: "",
    challenge: false,
    dataResponse: ""
  };

const biometricsMachine = createMachine(
    {
      id: "biometrics",
      initial: "validateCookie",
      context: initialContext,
      states: {
        validateCookie: {
          invoke: {
            id: "validateCookieId",
            src: validateCookie,
            onDone: [
              {
                cond:  conditions.isCookie,
                target: "validateParameters",
                actions: [actions.setSso, actions.setSetSession],
              },{
                target: "validateParameters",
              }
            ]
          },
        },
        validateParameters: {
          invoke: {
            id: "validateParametersId",
            src: fetchValidateParameters,
            onDone: [
              {
                cond:  conditions.isError,
                target: "errorData"
              },{
                target: "recaptcha",
                actions: assign({
                  clientId: (_context, event) => event.data.clientId,
                  data: (_context, event) => event.data.data,
                  sessionId: (_context, event) => event.data.sessionId,
                  customerTransactionUrl: (_context, event) => event.data.customerTransaction,
                }),
              }
            ]
          },
        },
        recaptcha: {
          on: {
            SETRECAPTCHAVALUE: [
              {
                target: "getBfKey",
                actions: assign({
                  recaptcha: (_context, event) => event.recaptcha
                }),
              }
            ]
          }
        },
        getBfKey: {
          invoke: {
            id: "getBfKeyId",
            src: getBfKey,
            onDone: [
              {
                cond: (_context, event) => event.data.key,
                target: "generateBfKey",
                actions: [assign({
                  bffKey: (_context, event) => event.data.key,
                  codeLog: _context => 'successGettingKey' 
                }), "saveLogs"],
              },{
                target: "errorData",
                actions: [assign({ codeLog: _context => 'errorGettingKey' }), "saveLogs"]
              }
            ],
            onError: {
              target: "errorData",
              actions: [assign({ codeLog: _context => 'errorGettingKey' }), "saveLogs"]
            },
          },
        },
        generateBfKey: {
          invoke: {
            id: "generateBfKeyId",
            src: generateBfKey,
            onDone: [
              {
                cond: conditions.successEncryptKey,
                target: "validateClientSoyYo",
                actions: [actions.setJweKey, actions.setAesKey, actions.setDataStr,actions.setSuccessGeneretingKey, "saveLogs"],
              },{
                target: "errorData",
                actions: [assign({ codeLog: _context => 'errorGeneretingKey' }), "saveLogs"]
              }
            ]
          },
        },
        refreshKeyValidateClientSoyYo: {
          invoke: {
            id: "refreshKeyValidateId",
            src: refreshBfKey,
            onDone: [
              {
                cond: conditions.successRefreshKey,
                target: "validateClientSoyYo",
                actions: [actions.setJweKey],
              },{
                target: "errorData",
                actions: [assign({
                  codeLog: (_context) => codesNoSDK.errorGeneretingKey.codeLog,
                })]
              }
            ]
          },
        },
        validateClientSoyYo: {
          invoke: {
            id: "validateClientId",
            src: validateClientState,
            onDone: [
              {
                cond: (_context, event) => event.data.resultCode === codesNoSDK.successCode.code && event.data.data,
                target: "decryptData",
                actions: assign({
                  encryptedDataClient: (_context, event) => event.data.data,
                  encryptedData: (_context, event) => event.data.data,
                  ivClient: (_context, event) => event.data.id,
                  iv: (_context, event) => event.data.id,
                  traceId: (_context, event) => event.data.traceId,
                })
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.resultCode === codesNoSDK.failedRecatpchaRetry.code && !_context.challenge,
                target: "challenge",
                actions: [assign({
                  challenge: (_context) => true,
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'failedRecatpchaRetry'
                }), "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.resultCode === codesNoSDK.failedRecatpcha.code,
                target: "unauthorized",
                actions: [assign({
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'failedRecatpcha'
                }), "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.resultCode === codesNoSDK.failedRecatpchaChallenge.code,
                target: "unauthorized",
                actions: [assign({
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'failedRecatpchaChallenge'
                }), "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.urlRedirect,
                target: "errorProcess",
                actions: [assign({
                  code: (_context, event) => event.data.resultCode,
                  redirectUrl: (_context, event) => event.data.urlRedirect,
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'errorServiceConsume'
                }), "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.resultCode === codesNoSDK.unauthorized.code,
                target: "unauthorized",
                actions: [assign({ codeLog: _context => 'unauthorized' }), "saveLogs"]
              },
              {
                target: "errorData",
                actions: [assign({ codeLog: _context => 'errorServiceConsume' }), "saveLogs"]
              }
            ],
            onError: [
              {
                cond: conditions.successServiceConsume && conditions.failedRecatpchaRetry,
                target: "challenge",
                actions: [assign({
                  challenge: (_context) => true,
                  resultLog: (_context, event) => event.data.response.data.resultDescription,
                  codeLog: _context => 'failedRecatpchaRetry'
                }), "saveLogs"]
              },
              {
                cond:  conditions.successServiceConsume && conditions.failedRecatpcha,
                target: "unauthorized",
                actions: [assign({
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'failedRecatpcha'
                }), "saveLogs"]
              },
              {
                cond: conditions.successServiceConsume && conditions.failedRecatpchaChallenge,
                target: "unauthorized",
                actions: [assign({
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'failedRecatpchaChallenge'
                }), "saveLogs"]
              },
              {
                cond: conditions.successServiceConsume && conditions.isUnauthorized,
                target: "unauthorized",
                actions: [assign({ codeLog: _context => 'unauthorized',  resultLog: (_context, event) => event.data.response.data.resultDescription }), "saveLogs"]
              },
              {
                target: "errorData",
                actions: [assign({ codeLog: _context => 'errorServiceConsume' }), "saveLogs"]
              }
            ],
          }
        },
        decryptData: {
          invoke: {
            id: "decryptDataId",
            src: decryptClientData,
            onDone: [
              {
                cond: (_context, event) => event.data.resultCode === "F071" || event.data.resultCode === "F070",
                target: "checkCustomerTransaction",
                actions: [assign({
                  enrollment: (_context, event) => event.data.resultCode ===  "F071",
                  transactionId: (_context, event) => event.data.data.transactionId,
                  token: (_context, event) => event.data.token,
                  redirectUrl: (_context, event) => event.data.urlRedirect,
                  documentNumber: (_context, event) => event.data.data.customer.documentNumber,
                  documentType: (_context, event) => event.data.data.customer.documentType,
                  email: (_context, event) => event.data.data.customer.email,
                  phone: (_context, event) => event.data.data.customer.phone,
                  phoneIndicative: (_context, event) => event.data.data.customer.phoneIndicative,
                  customerTransaction: (_context, event) => event.data.data.customer.customerTransaction,
                  showTermsAndConditions: (_context, event) => event.data.resultCode ===  "F071" ? false :  event.data.data.termsConditions,
                  blocked: (_context, event) => event.data.data.block,
                  attemps: (_context, event) => event.data.data.blockTry ? event.data.data.blockTry : 1,
                  cookie: (_context, event) => event.data.sso ?  event.data.sso  : "",
                  codeLog: _context => 'successDecryptingData'
                }), "documentEquivalent", "saveCookieInNavigator", "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.resultCode === codesNoSDK.failedRecatpchaRetry.code && !_context.challenge,
                target: "challenge",
                actions: [assign({
                  challenge: (_context) => true,
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'failedRecatpchaRetry'
                }), "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.resultCode === codesNoSDK.failedRecatpcha.code,
                target: "unauthorized",
                actions: [assign({
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'failedRecatpcha'
                }), "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.resultCode === codesNoSDK.failedRecatpchaChallenge.code,
                target: "unauthorized",
                actions: [assign({
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'failedRecatpchaChallenge'
                }), "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.urlRedirect,
                target: "errorProcess",
                actions: [assign({
                  code: (_context, event) => event.data.resultCode,
                  redirectUrl: (_context, event) => event.data.urlRedirect,
                  resultLog: (_context, event) => event.data.resultCode,
                  codeLog: _context => 'successDecryptingData'
                }), "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode && event.data.resultCode === codesNoSDK.unauthorized.code,
                target: "unauthorized",
                actions: [assign({ codeLog: _context => 'unauthorized' }), "saveLogs"]
              },
              {
                cond: (_context, event) => event.data.resultCode,
                target: "errorData",
                actions: [assign({ 
                  codeLog: _context => 'successDecryptingData',
                  resultLog: (_context, event) => event.data.resultCode,
                }), "saveLogs"]
              },
              {
                target: "errorData",
                actions: [assign({ 
                  codeLog: _context => 'successDecryptingData',
                  resultLog: (_context, event) => event.data,
                }), "saveLogs"]
              }
            ],
            onError: {
              target: "errorData",
              actions: [assign({ codeLog: _context => 'errorDecryptingData' }), "saveLogs"]
            },
          }
        },
        checkCustomerTransaction: {
          invoke: {
            id: "checkCustomerTransactionId",
            src: checkCustomerTransaction,
            onDone: [
              {
                cond: (_context, event) => event.data.mismatch,
                target: "errorProcess",
                actions: [assign({
                  code: _context => codesNoSDK.customerTransactionMismatch.code,
                  codeLog: _context => codesNoSDK.customerTransactionMismatch.codeLog,
                })]
              },
              {
                target: "checkUserState"
              }
            ]
          }
        },
        checkUserState: {
          invoke: {
            id: "checkUserStateId",
            src: checkUserState,
            onDone: [
              {
                cond: (_context, event) => event.data.blocked,
                target: "errorProcess",
                actions: [assign({
                  code: _context => codesNoSDK.errorBlockedUser.code,
                  codeLog: _context => codesNoSDK.errorBlockedUser.codeLog,
                })]
              },
              {
                cond: _context =>_context.contextScreen && _context.enrollment,
                target: "contexFlow",
                actions: assign({ contextScreen: _context => false })
              },
              {
                target: "checkTermsAndConditions"
              }
            ]
          }
        },
        challenge: {
          on: {
            SETRECAPTCHAVALUE: [
              {
                target: "refreshKeyValidateClientSoyYo",
                actions: assign({
                  recaptcha: (_context, event) => event.recaptcha
                }),
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ],
          }
        },
        contexFlow: {
          on: {
            CONTINUE: [
              {
                target: "checkTermsAndConditions"
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ],
          }
        },
        checkTermsAndConditions: {
          invoke: {
            id: "checkTermsAndConditionsId",
            src: checkTermsAndConditions,
            onDone: [
              {
                cond: (_context, event) => event.data.showTyC,
                target: "TermsAndConditions"
              },{
                cond: _context => _context.enrollment,
                target: "enrollmentUser"
              },
              {
                target: "authenticateUser"
              }
            ]
          }
        },
        TermsAndConditions: {
          on: {
            ACEPTTERMS: [
              {
                cond: _context => _context.enrollment,
                target: "enrollmentUser",
                actions: [assign({ codeLog: _context => 'successTAC' }), "saveLogs"]
              },
              {
                target: "authenticateUser",
                actions: [assign({ codeLog: _context => 'successTAC' }), "saveLogs"]
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ],
          }
        },
        authenticateUser: {
          on: {
            AUTHENTICATED: [
              {
                target: "authorizeTransaction",
                actions: assign({
                  sessionToken: (_context, event) => event.sessionToken
                }),
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ],
            SAVETRIES: [
              {
                target: "refreshKeyRetries",
              }
            ],
            SAVETRIESANDOUT: [
              {
                actions: assign({
                  processFailed: _context => true,
                  code: (_context, event) => event.code,
                }),
                target: "refreshKeyRetries",
              }
            ],
            CAMERAERROR: [
              {
                target: "cameraError",
              }
            ],
          }
        },
        cameraError: {
          on: {
            RETRY: [
              {
                cond: _context => _context.enrollment,
                target: "enrollmentUser"
              },
              {
                target: "authenticateUser"
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ],
          }
        },
        enrollmentUser: {
          on: {
            ACTIVATEUSER: [
              {
                target: "refreshKeyActivateUser",
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ],
            SAVETRIES: [
              {
                target: "refreshKeyRetries",
              }
            ],
            CAMERAERROR: [
              {
                target: "cameraError",
              }
            ],
          }
        },
        refreshKeyRetries: {
          invoke: {
            id: "refreshKeyRetriesId",
            src: refreshBfKey,
            onDone: [
              {
                cond: conditions.successRefreshKey,
                target: "saveUserTries",
                actions: [actions.setJweKey],
              },{
                target: "errorData",
                actions: [assign({
                  codeLog: (_context) => codesNoSDK.errorGeneretingKey.codeLog,
                })]
              }
            ]
          },
        },
        saveUserTries: {
          invoke: {
            id: "saveUserTriesId",
            src: saveUserTries,
            onDone: [
              {
                cond: _context => _context.processFailed,
                target: "errorProcess"
              },
              {
                cond: (_context, event) => event.data.resultCode === codesNoSDK.successCode.code,
                target: "retry"
              },
              {
                cond: (_context, event) => event.data.resultCode,
                target: "errorProcess",
                actions: [actions.setCodeResult]
              }
            ],
            onError:[ 
              {
                cond:  conditions.successServiceConsume,
                actions: [actions.setServiceError],
                target: "errorProcess",
              },
              {
                actions: [actions.setDefaultServiceError],
                target: "errorProcess",
              }
            ]
          }
        },
        retry: {
          on: {
            RETRY: [
              {
                target: "checkUserState",
                actions: [assign({
                  showTermsAndConditions: _context => true,
                })]
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ]
          }
        },
        refreshKeyActivateUser: {
          invoke: {
            id: "refreshKeyActivateUserId",
            src: refreshBfKey,
            onDone: [
              {
                cond: conditions.successRefreshKey,
                target: "activateUser",
                actions: [actions.setJweKey],
              },{
                target: "errorData",
                actions: [assign({
                  codeLog: (_context) => codesNoSDK.errorGeneretingKey.codeLog,
                })]
              }
            ]
          },
        },
        activateUser: {
          invoke: {
            id: "activateUserId",
            src: activateUser,
            onDone: [
              {
                cond: (_context, event) => event.data.resultCode === codesNoSDK.successCode.code,
                actions: assign({
                  sessionToken: (_context, event) => event.data.data.sessionToken
                }),
                target: "authorizeTransaction",
              },
              {
                cond: (_context, event) => event.data.resultCode === codesNoSDK.enrollmentFailed.code,
                actions: assign({
                  processFailed: _context => true,
                  code: _context => 'BF006'
                }),
                target: "refreshKeyRetries",
              },
              {
                target: "errorProcess",
                actions: [actions.setCodeResult]
              }
            ],
            onError:[ 
              {
                cond:  conditions.successServiceConsume,
                actions: [actions.setServiceError, actions.setJwsValue],
                target: "errorProcess",
              },
              {
                actions: [actions.setDefaultServiceError],
                target: "errorProcess",
              }
            ]
          }
        },
        authorizeTransaction: {
          on: {
            AUTHORIZATIONTRANSACTION: [
              {
                target: "refreshKeyTransaction",
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ]
          },
        },
        refreshKeyTransaction: {
          invoke: {
            id: "refreshKeyTransactionId",
            src: refreshBfKey,
            onDone: [
              {
                cond: conditions.successRefreshKey,
                target: "validateTransaction",
                actions: [actions.setJweKey],
              },{
                target: "errorData",
                actions: [assign({
                  codeLog: (_context) => codesNoSDK.errorGeneretingKey.codeLog,
                })]
              }
            ]
          },
        },
        validateTransaction: {
          invoke: {
            id: "validateTransactionId",
            src: validateTransaction,
            onDone: [
              {
                cond: (_context, event) => event.data.resultCode === codesNoSDK.successCode.code && event.data.data,
                target: "success",
                actions: [actions.setDataResponse, actions.setCodeResult]
              },
              {
                target: "errorProcess",
                actions: [actions.setCodeResult]
              }
            ],
            onError:[ 
              {
                cond:  conditions.successServiceConsume,
                actions: [actions.setServiceError],
                target: "errorProcess",
              },
              {
                actions: [actions.setDefaultServiceError],
                target: "errorProcess",
              }
            ]
          }
        },
        cancelOperation: {
          on: {
            RESTART: [
              {
                target: "validateClientSoyYo",
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ]
          },
        },
        retries: {
          on: {
            RESTART: [
              {
                target: "validateClientSoyYo",
              }
            ],
            REDIRECTERROR: [
              {
                target: "errorProcess",
                actions: [actions.setCodeError]
              }
            ]
          },
        },
        success: {
          after: {
            1: {
              actions: [assign({
                codeLog: _context => 'redirectResponse',
                resultLog: (_context) => `customer_code:${_context.code}`,
              }), "saveLogs", "redirectToTarget"]
            },
          },
        },
        unauthorized: {
          type: "final",
        },
        errorData: {
          type: "final",
        },
        errorProcess: {
          after: {
            1: {
              actions: [assign({
                codeLog: _context => 'redirectResponse',
                resultLog: (_context) => `customer_code:${_context.code}`,
              }), "saveLogs", "redirectToTargetError"]
            },
          },
        },
      }
    },
    {
      actions: {
        redirectToTarget: _context => {
          const urlRedirect = _context.customerTransactionUrl !== null && _context.customerTransactionUrl !== "" ? `${_context.redirectUrl}/?Code=${codesNoSDK.successCode.code}&Data=${_context.dataResponse}&customerTransaction=${_context.customerTransactionUrl}` : `${_context.redirectUrl}/?Code=${codesNoSDK.successCode.code}&Data=${_context.dataResponse}`;
          window.location.replace(urlRedirect)
        },
        redirectToTargetError: _context => {
          let urlRedirect = `${_context.redirectUrl}/?Code=${_context.code}`
          urlRedirect = _context.customerTransactionUrl !== null && _context.customerTransactionUrl !== "" ? `${urlRedirect}&customerTransaction=${_context.customerTransactionUrl}` : urlRedirect;
          urlRedirect = _context.jws !== null && _context.jws !== "" ?  `${urlRedirect}&jws=${_context.jws}` : urlRedirect;
          window.location.replace(urlRedirect)
        },
        documentEquivalent: assign(_context => {
          const docTypeValue = docTypes[_context.documentType]
          return { documentTypeEq: docTypeValue };
        }),
        saveCookieInNavigator: _context => {
          const minutesConverter =60000
          const cookieExpiration = parseInt(config.TIME_SSOFUB) * minutesConverter
          const { cookie, sessionId } = _context
          if(cookie && sessionId){
            addValueCookie("SSOFUB", cookieExpiration, cookie);
            addValueCookie("SSNFUB", cookieExpiration, sessionId);
          }
        },
        saveLogs: (_context) => {
          const result = {code:  _context.codeLog}
          saveLogs(_context, sourceId.web,codesNoSDK,result,_context.resultLog)
        }
      }
    }
);

export default biometricsMachine;